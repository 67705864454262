<template>
  <div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <vs-breadcrumb
        :items="
          [
            {
              title: 'Admin Tag Store',
              url: '/'
            },
            {
              title: 'Ventas',
              active: true
            }
          ]"
        ></vs-breadcrumb>
        <div>
          <vs-button color="success" icon="assignment_returned" @click="exportExcel" type="gradient">Exportar excel</vs-button>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col d-flex justify-content-between flex-wrap">
        <date-picker @change="getOrdersByDates()" style="width: 220px" v-model="daterange" range></date-picker>
        <div>
          <vs-input style="width: 400px" @keyup.enter="searchOrder()" placeholder="Buscar por referencia interna o bancaria (Presione ENTER)" v-model="search" class="inputx"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <vs-card>
          <vs-table class="mt-2" stripe :data="orders">
            <template slot="header">
              <h4>Lista de ordenes</h4>
            </template>
            <template slot="thead">
              <vs-th>
                Referencia wompi
              </vs-th>
              <vs-th>
                Cliente
              </vs-th>
              <vs-th>
                Fecha
              </vs-th>
              <vs-th>
                Total
              </vs-th>
              <vs-th>
                Detalle
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr class="text-left" :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td>
                  {{ tr.bank_id }}
                </vs-td>

                <vs-td>
                  {{ tr.user.name }}
                </vs-td>

                <vs-td>
                  {{ tr.created_at }}
                </vs-td>

                <vs-td>
                  {{ currencyFormat(tr.total) }}
                </vs-td>

                <vs-td>
                  <div class="d-flex">
                    <vs-button icon="create" @click="detailOrder(tr)" color="primary" type="gradient">
                      Detalle
                    </vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-pagination class="mt-2" :total="total_pages" v-model="current_page"></vs-pagination>
          </div>
        </vs-card>
      </div>
    </div>

  </div>
</template>

<script>
import { currencyFormat } from '@/helpers'
import ordersService from '@/services/orders.service'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { DatePicker },
  data() {
    return {
      search: '',
			menu_start: false,
			menu_end: false,
			daterange: [
				new Date(new Date().getFullYear(), new Date().getMonth(), 1),
				new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],
			orders: [],
			page: 1,
      total_pages: 1,
      current_page: 1,
			open: false,
			order: {
				user: {},
			},
			loading_export: false
    }
  },
  watch: {
		open(newVal) {
			if (!newVal) {
				this.order = {
					user: {},
				}
			}
		},
		current_page(newVal) {
			this.getOrders(newVal)
		}
  },
  methods: {
    searchOrder() {
      this.daterange = []
			this.getOrders()
		},
		getOrdersByDates() {
      if (this.daterange[0] && this.daterange[1]) {
        this.getOrders()
      }
		},
		currencyFormat(price) {
			return currencyFormat(price)
		},
		downloadExcel(data) {
			const url = window.URL.createObjectURL(new Blob([data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute(
				'download',
				`reporte-ventas-tienda-online.xlsx`
			)
			document.body.appendChild(link)
			link.click()
		},
		async exportExcel() {
      if (this.daterange[0] && this.daterange[1]) {
        this.$vs.loading({
          type: 'point'
        })
        const { data: res } = await ordersService.exportExcel(this.daterange)
        this.downloadExcel(res)
        this.$vs.loading.close()
      } else {
        this.$swal('Debe elegir un rango de fechas valido para generar el reporte', '', 'warning')
      }
		},
		async getOrders(page = 1) {
      this.$vs.loading({
        type: 'point'
      })
			const { data: res } = await ordersService.getOrders(page, this.search, this.daterange)
			if (res.success) {
				this.orders = res.data.data
				this.page = res.data.current_page
				this.total_pages = res.data.last_page
			} else {
				this.error = true
			}
      this.$vs.loading.close()
		},
		detailOrder(order) {
			this.order = order
			this.open = true
		}
	},
	created() {
		this.getOrders()
	}
}
</script>
