import api from '@/api'

class OrdersService {

	static getOrders(page, search, dates) {
		return api({
			method: 'get',
			params: { page, search, dates },
			url: 'orders'
		})
	}

	static exportExcel(dates) {
		return api({
			method: 'post',
			data: dates,
			url: 'export-excel-orders',
			responseType: "blob",
		})
	}

}

export default OrdersService
